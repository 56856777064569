import React from 'react';
import Layout from '../components/Layout';

const ContactUs = () => {
  return (
    <Layout>
      <div className="policy-page">
        <h1>Contact Us</h1>
        <p>For any inquiries or support, please reach out to us:</p>
        <p>Phone: +91 [6398896398]</p>
        <p>Email: [info@gemmanagers.com]</p>
        <p>Address: [Skyseeker Research Pvt. Ltd, 74/II, Opp. Gate no. 2, C-Cross Road, Mumbai- 400093]</p>
      </div>
    </Layout>
  );
};

export default ContactUs;
