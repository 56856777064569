import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'slick-carousel/slick/slick.css'; // Import Slick CSS
import 'slick-carousel/slick/slick-theme.css'; // Import Slick theme CSS
import LandingApp from './LandingApp'; // Ensure we are using LandingApp

ReactDOM.render(
  <React.StrictMode>
    <LandingApp /> {/* This should be LandingApp, not App */}
  </React.StrictMode>,
  document.getElementById('root')
);