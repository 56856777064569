// src/components/AboutUs.js
import React from 'react';
import Layout from './Layout'; // Import the Layout component
import './AboutUs.css'; // Create this CSS file for custom styles

function AboutUs() {
    return (
    <Layout>
        <div className="about-us-container">
            <h1>About Us</h1>
            <p>Welcome to Tender Managers! We specialize in providing comprehensive solutions for managing tenders, helping businesses streamline their processes and achieve better results. Our team is dedicated to offering expertise, tools, and support to make tender management easier and more effective for companies of all sizes.</p>
            <p>With years of experience in the industry, we understand the unique challenges businesses face when participating in tenders. Our mission is to simplify this process, empowering you to focus on what matters most – winning more opportunities and growing your business.</p>
        </div>
    </Layout>
    );
}

export default AboutUs;
