import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db, auth } from './firebaseConfig';  // Import Firestore and Firebase auth
import { onAuthStateChanged } from 'firebase/auth';

const SubscriptionCheck = () => {
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true; // Flag to prevent state updates after unmount

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user && isMounted) {
        const phoneNumber = user.phoneNumber;
        const subscriptionDocRef = doc(db, 'subscriptions', phoneNumber);

        try {
          const docSnap = await getDoc(subscriptionDocRef);

          if (docSnap.exists()) {
            const subscriptionData = docSnap.data();
            const currentTime = new Date();

            let endDate;
            if (subscriptionData.endDate) {
              if (subscriptionData.endDate.toDate) {
                // Firestore Timestamp
                endDate = subscriptionData.endDate.toDate();
              } else if (typeof subscriptionData.endDate === 'string') {
                // ISO String
                endDate = new Date(subscriptionData.endDate);
              } else {
                // Already a Date object
                endDate = subscriptionData.endDate;
              }

              if (endDate && endDate.getTime() > currentTime.getTime()) {
                // Subscription is active
                navigate('/tender-selection');
              } else {
                // Subscription expired
                navigate('/subscription');
              }
            } else {
              // No endDate field
              navigate('/subscription');
            }
          } else {
            // No subscription found
            navigate('/subscription');
          }
        } catch (error) {
          console.error('Error checking subscription:', error);
          navigate('/subscription');  // Redirect to subscription page on error
        }
      } else {
        // If no user is logged in, redirect to login
        navigate('/login');
      }
    });

    return () => {
      isMounted = false; // Clean up to prevent memory leaks
      unsubscribe();
    };
  }, [navigate]);

  return null; // Component doesn't render anything after navigation
};

export default SubscriptionCheck;
