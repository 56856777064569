import React from 'react';
import { Link } from 'react-router-dom';
import Layout from './components/Layout'; // Make sure Layout is correctly imported

function TenderSelectionPage() {
  return (
    <Layout>
    <div>
      <h1>Welcome to Tender Managers</h1>
      <p>Select a section to view the tenders:</p>
      <ul>
        <li>
          <Link to="/gem-bids">GeM Bids</Link>
        </li>
        <li>
          <Link to="/state-cppp-tenders">State CPPP Tenders</Link>
        </li>
        <li>
          <Link to="/central-cppp-tenders">Central CPPP Tenders</Link>
        </li>
      </ul>
    </div>
    </Layout>
  );
}

export default TenderSelectionPage;
