import React, { useState, useMemo, useEffect } from "react";
import * as XLSX from "xlsx";
import "./GeMCategories.css";
import NavBar from "./NavBar";
import Footer from "./Footer";

const GeMCategories = () => {
  const [categoriesData, setCategoriesData] = useState([]);
  const [selectedSector, setSelectedSector] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  // Fetch JSON data on component mount
  useEffect(() => {
    fetch("/gem_categories.json")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch categories");
        }
        return response.json();
      })
      .then((data) => {
        setCategoriesData(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }, []);

  // Filtered categories within the selected sector
  const filteredCategories = useMemo(() => {
    if (!selectedSector) return [];
    return selectedSector.categories.filter((category) =>
      category.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [selectedSector, searchQuery]);

  // Global search results
  const globalSearchResults = useMemo(() => {
    if (!searchQuery) return [];
    const results = [];
    categoriesData.forEach((sector) => {
      const matchedCategories = sector.categories.filter((category) =>
        category.toLowerCase().includes(searchQuery.toLowerCase())
      );
      if (matchedCategories.length) {
        results.push({ sector: sector.sector, categories: matchedCategories });
      }
    });
    return results;
  }, [categoriesData, searchQuery]);

  const handleCategoryToggle = (category) => {
    setSelectedCategories((prev) =>
      prev.includes(category)
        ? prev.filter((c) => c !== category)
        : [...prev, category]
    );
  };

  const handleExport = () => {
    const worksheet = XLSX.utils.json_to_sheet(
      selectedCategories.map((category, index) => ({
        "S. No": index + 1,
        Category: category,
      }))
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Selected Categories");
    XLSX.writeFile(workbook, "Selected_Categories.xlsx");
  };

  const handleClearSelection = () => {
    setSelectedCategories([]);
  };

  const handleLogin = () => {
    window.location.href = "/login"; // Redirect to login page
  };

  const totalCategories = categoriesData.reduce(
    (total, sector) => total + sector.categories.length,
    0
  );

  if (isLoading) {
    return <div className="loading">Loading categories...</div>;
  }

  return (
    <>
      <NavBar />
      <div className="gem-categories-container">
        <h1 className="page-title">GeM Categories</h1>
        <div className="meta-info">
          <p>Last updated on: December 8, 2024</p>
          <p>Total Categories: {totalCategories}</p>
        </div>

        {/* Global Search Bar */}
        <div className="global-search-container">
          <label className="global-search-label">Search Across All Sectors and ALL GeM Categories:</label> 
          <input
            type="text"
            placeholder="Type keywords to Search GeM categories"
            className="global-search-input"
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>

        {/* Global Search Results */}
        {globalSearchResults.length > 0 && (
          <div className="global-search-results">
            <h2>Global Search Results</h2>
            {globalSearchResults.map((result, index) => (
              <div key={index} className="sector-result">
                <h3>{result.sector}</h3>
                <ul>
                  {result.categories.map((category, catIndex) => (
                    <li key={catIndex}>
                      <label>
                        <input
                          type="checkbox"
                          checked={selectedCategories.includes(category)}
                          onChange={() => handleCategoryToggle(category)}
                        />
                        {category}
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        )}

        {/* Sectors Grid */}
        <h2>Select Your Relevant Sector to View and Download GeM Categories</h2>
        <div className="sectors-grid">
          {categoriesData.map((sector, index) => (
            <div
              key={index}
              className={`sector-card ${
                selectedSector === sector ? "selected" : ""
              }`}
              onClick={() => setSelectedSector(sector)}
            >
              <h3>{`${index + 1}. ${sector.sector}`}</h3>
              <p>({sector.categories.length} Categories)</p>
            </div>
          ))}
        </div>

        {/* Sector-Specific Categories */}
        {selectedSector && (
          <div className="categories-section">
            <h2>Categories in {selectedSector.sector}</h2>
            <label className="search-label">Search and view Sector Specific Results Below and Global Results on Top:</label>
            <input
              type="text"
              placeholder="Type Keywords to Search GeM Categories"
              className="search-input"
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <div className="categories-grid">
              {filteredCategories.map((category, catIndex) => (
                <div key={catIndex} className="category-card">
                  <label className="category-label">
                    <input
                      type="checkbox"
                      checked={selectedCategories.includes(category)}
                      onChange={() => handleCategoryToggle(category)}
                    />
                    <span className="category-serial">{`${catIndex + 1}.`}</span>
                    {category}
                  </label>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Persistent Buttons */}
        <div className="fixed-buttons">
          <button
            className="download-button"
            onClick={handleExport}
            disabled={selectedCategories.length === 0}
          >
            Download {selectedCategories.length} Categories List
          </button>
          <button
            className="clear-button"
            onClick={handleClearSelection}
            disabled={selectedCategories.length === 0}
          >
            Clear Selection
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default GeMCategories;
