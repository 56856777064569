// src/components/Services.js
import React from 'react';
import Layout from './Layout'; // Import the Layout component
import './Services.css'; // Create this CSS file for custom styles

function Services() {
    return (
        <Layout>
            <div className="services-container">
                <h1>Our Services</h1>
                
                <section className="service">
                    <h2>Tender Tracker Service</h2>
                    <p>
                        Our Tender Tracker Service compiles government tenders from various official portals, including GeM, state government portals, and CPPP, and presents them in a user-friendly, searchable format. Leveraging advanced AI technology, we automate data gathering and curation to create an interface as simple and effective as an Excel file. This allows you to search, filter, and manage tenders efficiently, saving valuable time and effort.
                    </p>
                </section>

                <section className="service">
                    <h2>Standard and Customised Reports</h2>
                    <p>
                        We offer both standard and customized reports to provide comprehensive insights into the government procurement market. Our reports utilize AI-driven data analysis to deliver in-depth market intelligence, including product/service analysis and other critical metrics. These reports equip you with the knowledge needed to make informed decisions and strategically navigate government procurement opportunities.
                    </p>
                </section>

                <section className="service">
                    <h2>Customised Consulting Services</h2>
                    <p>
                        For personalized, human-interfaced consulting services, our sister company, GeM Managers, is ready to assist. We offer tailored solutions to meet unique client requirements, whether they involve advanced procurement strategy, direct consultation, or specialized market analysis. Get in touch with us at <a href="mailto:sales@gemmanagers.com">sales@gemmanagers.com</a> or call us at <strong>+91-6398896398</strong> to discuss your needs.
                    </p>
                </section>
            </div>
        </Layout>
    );
}

export default Services;
