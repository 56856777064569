import React from 'react';
import Layout from '../components/Layout';

const PricingPolicy = () => {
  return (
    <Layout>
      <div className="policy-page">
        <h1>Pricing Policy</h1>
        <p>Last updated: 8th November 2024

1. Subscription Plans
Our platform offers various subscription plans tailored to different user needs. Please refer to our subscription page for detailed pricing.

2. Reports
Pricing for individual reports is available on the reports section. No refunds are provided for report purchases.

3. Taxes
All prices are inclusive of applicable taxes, such as GST. Additional charges, if any, are displayed before payment.

4. Payment Methods
Payments are accepted through secure channels. By making a payment, you agree to our terms, including our refund and data usage policies.

5. Price Modifications
Prices may change at our discretion. Existing subscribers will be notified of price changes before renewal.</p>
        {/* Add more detailed pricing policies */}
      </div>
    </Layout>
  );
};

export default PricingPolicy;
