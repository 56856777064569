import React, { useState, useEffect, useCallback } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { TextField, Button } from '@mui/material';
import moment from 'moment';
import Layout from './components/Layout'; // Import the Layout component

function StateCPPPTenders() {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [search, setSearch] = useState('');
  const [sortModel, setSortModel] = useState([{ field: 'SNo', sort: 'asc' }]);
  const [customPage, setCustomPage] = useState('');

  const fetchData = useCallback(() => {
    const params = new URLSearchParams({
      page: page + 1,
      limit: pageSize,
      search,
      sortField: sortModel[0]?.field || 'SNo',
      sortOrder: sortModel[0]?.sort || 'asc',
    });

    const apiUrl = process.env.REACT_APP_API_URL || 'https://webapptest1-783744cf01e6.herokuapp.com';

    fetch(`${apiUrl}/api/state-cppp-data?${params.toString()}`)
      .then((response) => response.json())
      .then((result) => {
        setData(result.data);
        setTotal(result.total);
      })
      .catch((error) => console.error('Error:', error));

  }, [page, pageSize, search, sortModel]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setPage(0);
  };

  const handleSortModelChange = (model) => {
    setSortModel(model);
  };

  const handleCustomPageChange = () => {
    const parsedPage = parseInt(customPage, 10);
    const maxPage = Math.ceil(total / pageSize) - 1;
    if (parsedPage >= 1 && parsedPage <= maxPage + 1) {
      setPage(parsedPage - 1);
    } else {
      alert(`Please enter a page between 1 and ${maxPage + 1}`);
    }
  };

  const columns = [
    { field: 'SNo', headerName: 'S No', width: 90 },
    { 
      field: 'Title', 
      headerName: 'Title/Ref. No./Tender ID', 
      flex: 2, 
      minWidth: 300, 
      renderCell: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', wordBreak: 'break-all', padding: '5px' }}>
          {params.value}
        </div>
      ),
    },
    { 
      field: 'StateName', 
      headerName: 'State Name', 
      flex: 2, 
      minWidth: 200, 
      renderCell: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', wordBreak: 'break-all', padding: '5px' }}>
          {params.value}
        </div>
      ),
    },
    { 
      field: 'TenderID', 
      headerName: 'Tender ID', 
      flex: 2, 
      minWidth: 150, 
      renderCell: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', wordBreak: 'break-all', padding: '5px' }}>
          {params.value}
        </div>
      ),
    },
    {
      field: 'PortalLink',
      headerName: 'Portal Link',
      flex: 2,
      minWidth: 250,
      renderCell: (params) => (
        <a href={params.value} target="_blank" rel="noopener noreferrer" style={{ whiteSpace: 'normal', wordWrap: 'break-word', wordBreak: 'break-all', display: 'block', padding: '5px' }}>
          {params.value}
        </a>
      ),
    },
    {
      field: 'ePublishedDate',
      headerName: 'Published Date',
      width: 200,
      valueGetter: (params) => {
        const dateValue = params.row?.ePublishedDate;
        return dateValue ? moment(dateValue).format('DD-MM-YYYY hh:mm A') : '';
      },
    },
    {
      field: 'BidSubmissionClosingDate',
      headerName: 'Bid Submission Closing Date',
      width: 200,
      valueGetter: (params) => {
        const dateValue = params.row?.BidSubmissionClosingDate;
        return dateValue ? moment(dateValue).format('DD-MM-YYYY hh:mm A') : '';
      },
    },
  ];

  return (
    <Layout>
    <div style={{ height: '90vh', width: '100%', padding: '1rem' }}>
      <h1>State CPPP Tenders</h1>
      <h3>State Govt Tenders Updated as on 24.11.2024</h3>

      <TextField
        label="Search"
        variant="outlined"
        value={search}
        onChange={handleSearchChange}
        style={{ marginBottom: '1rem', width: '300px' }}
        helperText="Enter keywords separated by commas (e.g. municipal, roads, drains etc.)"
      />

      <div style={{ marginBottom: '1rem' }}>
        <TextField
          label="Go to Page"
          variant="outlined"
          value={customPage}
          onChange={(e) => setCustomPage(e.target.value)}
          style={{ marginRight: '1rem', width: '150px' }}
        />
        <Button variant="contained" onClick={handleCustomPageChange}>
          Go
        </Button>
        <p>
          Page {page + 1} of {Math.ceil(total / pageSize)} (Showing {pageSize} entries per page)
        </p>
      </div>

      <DataGrid
        rows={data}
        columns={columns}
        rowCount={total}
        pagination
        paginationMode="server"
        page={page}
        onPageChange={handlePageChange}
        pageSize={pageSize}
        onPageSizeChange={handlePageSizeChange}
        rowsPerPageOptions={[25, 50, 100]}
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        getRowId={(row) => row._id || row.SNo}
        autoHeight
        getRowHeight={() => 'auto'}
      />
    </div>
    </Layout>
  );
}

export default StateCPPPTenders;
