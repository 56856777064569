import React from 'react';
import Layout from '../components/Layout'; // Import Layout for consistency

const TermsAndConditions = () => {
  return (
    <Layout>
      <div className="policy-page">
        <h1>Terms and Conditions</h1>
        <p>Last updated: 8th November 2024

Welcome to Tender Managers!

By accessing and using our platform, you agree to comply with and be bound by these terms and conditions. Tender Managers is a private entity with no affiliation or official relationship with any government organization. Our services aim to facilitate participation in government tenders but do not replace any government entity or their services.

1. Introduction
These terms govern your use of our website, services, and all content provided. If you disagree with any part of these terms, please refrain from using our services.

2. Eligibility
You must be at least 18 years old to use our services. By using the platform, you represent and warrant that you meet this eligibility requirement.

3. Registration and Accounts
You may need to create an account to access certain features. You are responsible for safeguarding your password and agree not to share it with others.

4. Payments and Pricing

All payments for our services, including subscriptions and reports, are processed securely through our payment gateway partner.
Pricing is subject to applicable taxes (e.g., GST).
5. Use of Data
Unauthorized use, duplication, or redistribution of our data, content, or reports without our explicit permission is strictly prohibited.

6. Use of Platform
Our data and reports are provided solely for facilitating participation in government tenders. Users must use the data responsibly and comply with applicable laws.

7. Data Usage
We may use user data to enhance, improve our services etc. This may include analyzing usage patterns, improving the user interface, developing new features etc.

8. Intellectual Property
All content, including text, graphics, and logos, on our platform is owned by Tender Managers and protected by copyright laws.

9. Termination
We reserve the right to terminate your access if you violate these terms.

10. Limitation of Liability
To the maximum extent permitted by law, we are not liable for any direct, indirect, incidental, or consequential damages arising from your use of our platform.

11. Amendments
We reserve the right to modify these terms at any time, effective immediately upon posting.</p>
        {/* Add more detailed terms and conditions as needed */}
      </div>
    </Layout>
  );
};

export default TermsAndConditions;
