import { useState, useEffect, createContext, useContext } from 'react';
import { auth, db } from './firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userDetails, setUserDetails] = useState({
    isSubscribed: false,
    planType: null,
    endDate: null,
    startDate: null,
    status: 'Inactive',
  });

  const fetchUserSubscription = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        const subscriptionDocRef = doc(db, 'subscriptions', user.phoneNumber);
        const docSnap = await getDoc(subscriptionDocRef);

        if (docSnap.exists()) {
          const subscriptionData = docSnap.data();
          const currentTime = new Date();
          let endDate, startDate;

          // Handle endDate
          if (subscriptionData.endDate) {
            const endDateRaw = subscriptionData.endDate;
            endDate = endDateRaw.toDate
              ? endDateRaw.toDate()
              : endDateRaw instanceof Date
              ? endDateRaw
              : new Date(endDateRaw);
          } else {
            endDate = null;
          }

          // Handle startDate
          if (subscriptionData.startDate) {
            const startDateRaw = subscriptionData.startDate;
            startDate = startDateRaw.toDate
              ? startDateRaw.toDate()
              : startDateRaw instanceof Date
              ? startDateRaw
              : new Date(startDateRaw);
          } else {
            startDate = null;
          }

          setUserDetails({
            isSubscribed: endDate && endDate.getTime() > currentTime.getTime(),
            planType: subscriptionData.plan || 'N/A',
            endDate,
            startDate,
            status: subscriptionData.status || 'Inactive',
          });
        } else {
          // No subscription data
          setUserDetails({
            isSubscribed: false,
            planType: null,
            endDate: null,
            startDate: null,
            status: 'Inactive',
          });
        }
      } else {
        // User not logged in
        setUserDetails({
          isSubscribed: false,
          planType: null,
          endDate: null,
          startDate: null,
          status: 'Inactive',
        });
      }
    } catch (error) {
      console.error('Error fetching subscription details:', error);
      setUserDetails({
        isSubscribed: false,
        planType: null,
        endDate: null,
        startDate: null,
        status: 'Inactive',
      });
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setIsLoggedIn(!!user);
      if (user) {
        fetchUserSubscription();
      } else {
        setUserDetails({
          isSubscribed: false,
          planType: null,
          endDate: null,
          startDate: null,
          status: 'Inactive',
        });
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <UserContext.Provider
      value={{ isLoggedIn, ...userDetails, refreshUserDetails: fetchUserSubscription }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);