// LoginPage.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { firebase, auth } from './firebaseConfig';
import * as firebaseui from 'firebaseui';
import Layout from './components/Layout'; // Adjusted to reference the correct directory

const LoginPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const uiConfig = {
      signInOptions: [
        {
          provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
          recaptchaParameters: {
            type: 'image',
            size: 'invisible',
            badge: 'bottomleft',
          },
          defaultCountry: 'IN',
        },
      ],
      callbacks: {
        signInSuccessWithAuthResult: (authResult) => {
          console.log('User signed in successfully:', authResult.user);
          navigate('/subscription-check');
          return false;
        },
        uiShown: () => {
          console.log('UI rendered successfully');
        },
      },
    };

    const ui =
      firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth);

    ui.start('#firebaseui-auth-container', uiConfig);

    // Clean up to prevent multiple instances
    return () => {
      ui.reset();
    };
  }, [navigate]);

  return (
    <Layout>
    <div>
      <h2>Tender Managers Login Page</h2>
      <div id="firebaseui-auth-container"></div>
    </div>
    </Layout>
  );
};

export default LoginPage;
