import React from 'react';
import NavBar from './NavBar'; // Import your existing NavBar component
import Footer from './Footer'; // Import your existing Footer component
import './Statistics.css'; // Optional for future styling

const Statistics = () => {
  return (
    <div className="page-container">
      <NavBar /> {/* Include NavBar */}
      <main className="content-container">
        <h1>Statistics</h1>
        <p>This page will display statistical data related to procurement. Content will be added soon.</p>
      </main>
      <Footer /> {/* Include Footer */}
    </div>
  );
};

export default Statistics;
