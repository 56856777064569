import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import { auth } from '../firebaseConfig';
import { useUserContext } from '../UserContext'; // Import user context
import { useNavigate } from 'react-router-dom';

const ActivePlanPage = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { isSubscribed, planType, endDate, startDate, status } = useUserContext(); // Use 'endDate' instead of 'expiryDate'

  useEffect(() => {
    // Additional logic if needed, e.g., redirecting based on user status
    setLoading(false);
  }, []);

  // Format dates professionally
  const formatDate = (date) => {
    if (!date) return 'N/A';
    try {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(date).toLocaleDateString(undefined, options);
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Invalid Date';
    }
  };

  if (loading) {
    return <Layout><p>Loading...</p></Layout>;
  }

  const handleManageSubscription = () => {
    navigate('/subscription');
  };

  return (
    <Layout>
      <div className="active-plan-container">
        <h1>Active Plan Details</h1>
        {isSubscribed ? (
          <div className="plan-details">
            <p><strong>Plan:</strong> {planType}</p>
            <p><strong>Start Date:</strong> {formatDate(startDate)}</p>
            <p><strong>End Date:</strong> {formatDate(endDate)}</p> {/* Changed 'Expiry Date' to 'End Date' */}
            <p><strong>Status:</strong> {status}</p>
            <button className="manage-subscription-button" onClick={handleManageSubscription}>
              Manage Subscription
            </button>
          </div>
        ) : (
          <div className="no-plan-details">
            <p>No active plan. Please subscribe to a plan.</p>
            <button className="subscribe-button" onClick={handleManageSubscription}>
              Subscribe Now
            </button>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default ActivePlanPage;
