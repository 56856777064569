import React, { useState, useEffect } from 'react';
import { db, auth } from '../firebaseConfig'; // Adjust the import path if needed
import { collection, getDocs } from 'firebase/firestore';
import Layout from './Layout'; // Corrected path for Layout

const FavoriteTenders = () => {
  const [gemFavorites, setGemFavorites] = useState([]);

  useEffect(() => {
    // Function to fetch GeM favorite tenders for the current user
    const fetchGemFavorites = async () => {
      try {
        const userId = auth.currentUser?.uid;

        if (userId) {
          // Fetch GeM Favorites
          const gemCollectionRef = collection(db, 'users', userId, 'favoriteGeMTenders');
          const gemDocs = await getDocs(gemCollectionRef);
          const gemData = gemDocs.docs.map(doc => doc.data());
          setGemFavorites(gemData);
        }
      } catch (error) {
        console.error('Error fetching GeM favorite tenders:', error);
      }
    };

    fetchGemFavorites();
  }, []);

  return (
    <Layout>
      <div className="favorites-page">
        <h1>Favorite Tenders</h1>

        <div className="favorites-section">
          <h2>GeM Favorites</h2>
          {gemFavorites.length > 0 ? (
            <ul>
              {gemFavorites.map((tender, index) => (
                <li key={index}>
                  <p><strong>Bid Number:</strong> {tender.bidNumber}</p>
                  <p><strong>Items:</strong> {tender.items}</p>
                  <p><strong>Quantity:</strong> {tender.quantity}</p>
                  <p><strong>District:</strong> {tender.district}</p>
                  <p><strong>Bid Type:</strong> {tender.bidType}</p>
                </li>
              ))}
            </ul>
          ) : (
            <p>No GeM favorites found.</p>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default FavoriteTenders;
