import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import { auth, db } from '../firebaseConfig'; // Adjust path if needed
import { doc, getDoc, setDoc } from 'firebase/firestore';

const MyProfile = () => {
  const [userData, setUserData] = useState({
    companyName: '',
    companyAddress: '',
    keyPersonName: '',
    keyPersonEmail: '',
    phoneNumber: '', // Assuming this is part of the profile data to display
  });
  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(false);
  const currentUser = auth.currentUser;

  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser) {
        try {
          const docRef = doc(db, 'users', currentUser.phoneNumber);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            setUserData(docSnap.data());
          } else {
            console.error('No user data found');
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
      setLoading(false);
    };

    fetchUserData();
  }, [currentUser]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleSave = async () => {
    if (currentUser) {
      try {
        const docRef = doc(db, 'users', currentUser.phoneNumber);
        await setDoc(docRef, userData);
        alert('Profile updated successfully!');
        setEditing(false); // Exit editing mode
      } catch (error) {
        console.error('Error updating user data:', error);
      }
    }
  };

  if (loading) {
    return (
      <Layout>
        <p>Loading...</p>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className="profile-page">
        <h1>My Profile</h1>
        {editing ? (
          <div>
            <h2>Company Details</h2>
            <label>
              Company Name:
              <input
                type="text"
                name="companyName"
                value={userData.companyName}
                onChange={handleChange}
              />
            </label>
            <label>
              Company Address:
              <input
                type="text"
                name="companyAddress"
                value={userData.companyAddress}
                onChange={handleChange}
              />
            </label>

            <h2>Key Person Details</h2>
            <label>
              Name:
              <input
                type="text"
                name="keyPersonName"
                value={userData.keyPersonName}
                onChange={handleChange}
              />
            </label>
            <label>
              Email:
              <input
                type="email"
                name="keyPersonEmail"
                value={userData.keyPersonEmail}
                onChange={handleChange}
              />
            </label>

            <h2>Phone Number</h2>
            <p>{currentUser.phoneNumber}</p>

            <button onClick={handleSave}>Save Changes</button>
            <button onClick={() => setEditing(false)}>Cancel</button>
          </div>
        ) : (
          <div>
            <h2>Company Details</h2>
            <p>Name: {userData.companyName || 'N/A'}</p>
            <p>Address: {userData.companyAddress || 'N/A'}</p>

            <h2>Key Person Details</h2>
            <p>Name: {userData.keyPersonName || 'N/A'}</p>
            <p>Email: {userData.keyPersonEmail || 'N/A'}</p>

            <h2>Phone Number</h2>
            <p>{currentUser.phoneNumber}</p>

            <button onClick={() => setEditing(true)}>Edit Profile</button>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default MyProfile;
