import React from 'react';
import Layout from '../components/Layout';

const PrivacyPolicy = () => {
  return (
    <Layout>
      <div className="policy-page">
        <h1>Privacy Policy</h1>
        <p>Last updated: 8th November 2024

At Tender Managers, your privacy is of utmost importance. This policy outlines how we collect, use, and protect your personal data.

1. Information We Collect

Personal information provided during registration (e.g., name, phone number, email address).
Transaction details (e.g., payments, subscriptions, reports purchased).
2. Use of Information

We use your data to deliver services, process payments, and communicate updates.
Data may be analyzed to improve our offerings, enhance user experience, develop new features etc.

3. Security
We implement measures to protect your data but cannot guarantee absolute security. Users must also take steps to safeguard their accounts.

4. Cookies
We use cookies to improve your browsing experience. By using our site, you agree to our use of cookies.

5. Unauthorized Use
Unauthorized use of our data or reports is prohibited and may result in legal action.</p>
        {/* Add more detailed policy content */}
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
