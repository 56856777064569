import React, { useEffect, useState } from 'react';
import './GovernmentPortals.css';
import NavBar from './NavBar'; // Import your existing navbar component
import Footer from './Footer'; // Import your existing footer component

const GovernmentPortals = () => {
  const [centralPortals, setCentralPortals] = useState([]);
  const [statePortals, setStatePortals] = useState([]);
  const [lastUpdated, setLastUpdated] = useState("December 8, 2024"); // Example default

  useEffect(() => {
    // Fetch Central Portals Data
    fetch('/central_portals.json')
      .then((response) => response.json())
      .then((data) => setCentralPortals(data))
      .catch((error) => console.error("Error fetching central portals:", error));

    // Fetch State Portals Data
    fetch('/state_portals.json')
      .then((response) => response.json())
      .then((data) => setStatePortals(data))
      .catch((error) => console.error("Error fetching state portals:", error));
  }, []);

  return (
    <div className="page-container">
      <NavBar /> {/* Include NavBar */}
      <main className="content-container">
        <h1>Government Procurement Portals</h1>
        <p>
          Below is a curated list of central and state government portals where procurement activities
          take place. Use these portals to access tenders and bidding opportunities.
        </p>
        <p className="last-updated">Last Updated: {lastUpdated}</p>

        {/* Two-column layout */}
        <div className="portals-grid">
          {/* Central Govt Portals Section */}
          <section className="portals-column">
            <h2>Central Government Portals ({centralPortals.length})</h2>
            <ul className="central-portals-list">
              {centralPortals.map((portal, index) => (
                <li key={index}>
                  <span className="serial-number">{index + 1}. </span>
                  <a href={portal["Central Govt Portals"]} target="_blank" rel="noopener noreferrer">
                    {portal["Central Govt Portals"]}
                  </a>
                </li>
              ))}
            </ul>
          </section>

          {/* State Govt Portals Section */}
          <section className="portals-column">
  <h2>State Government Portals ({statePortals.length})</h2>
  <ul className="state-portals-list">
    {statePortals.map((portal, index) => (
      <li key={index} className="state-portal-item">
        <span className="serial-number">{index + 1}. </span>
        <strong>{portal["State Name"]}</strong>
        <a href={portal["Portal"]} target="_blank" rel="noopener noreferrer">
          Visit Portal
        </a>
      </li>
    ))}
  </ul>
</section>

        </div>
      </main>
      <Footer /> {/* Include Footer */}
    </div>
  );
};

export default GovernmentPortals;
