import React, { useEffect, useState } from 'react';
import { auth, db } from './firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, Timestamp } from 'firebase/firestore'; // Ensure Timestamp is imported
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const [authChecked, setAuthChecked] = useState(false);
  const [hasAccess, setHasAccess] = useState(false);

  useEffect(() => {
    let isMounted = true;

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user && isMounted) {
        const phoneNumber = user.phoneNumber;
        const docRef = doc(db, 'subscriptions', phoneNumber);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          if (data && data.endDate) {
            const endDate = data.endDate instanceof Timestamp ? data.endDate.toDate() : new Date(data.endDate);
            const today = new Date();

            if (endDate > today) {
              // Subscription is valid
              setHasAccess(true);
            } else {
              // Subscription expired
              setHasAccess(false);
            }
          } else {
            // No endDate field
            setHasAccess(false);
          }
        } else {
          // No subscription document
          setHasAccess(false);
        }
      } else {
        // User is not logged in
        setHasAccess(false);
      }

      if (isMounted) {
        setAuthChecked(true);
      }
    });

    return () => {
      isMounted = false;
      unsubscribe();
    };
  }, []);

  if (!authChecked) {
    return <div>Loading...</div>;
  }

  if (hasAccess) {
    return children;
  } else {
    return <Navigate to="/subscription" />;
  }
};

export default ProtectedRoute;
