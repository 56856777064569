// src/components/SubscriptionPricing.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from './Layout'; // Import the Layout component
import './SubscriptionPricing.css'; // Create this CSS file for custom styles
import { useUserContext } from '../UserContext'; // Import UserContext hook

function SubscriptionPricing() {
    const navigate = useNavigate();
    const { isLoggedIn } = useUserContext(); // Get logged-in status from UserContext

    // Function to handle click event
    const handleSubscriptionClick = () => {
        if (isLoggedIn) {
            navigate('/subscription'); // Navigate to the subscription page
        } else {
            navigate('/login'); // Navigate to the login page
        }
    };

    return (
        <Layout>
            <div className="subscription-pricing-container">
                <h1>Subscription Pricing</h1>
                <p>Choose a subscription plan that suits your needs and gain access to comprehensive tender tracking from GeM, state government portals, and central government sources across India.</p>
                <div className="pricing-cards">
                    <div className="pricing-card" onClick={handleSubscriptionClick}>
                        <h2>Weekly Plan</h2>
                        <p>Access for 7 days</p>
                        <h3>₹499</h3>
                        <button>Subscribe Now</button>
                    </div>
                    <div className="pricing-card" onClick={handleSubscriptionClick}>
                        <h2>Monthly Plan</h2>
                        <p>Access for 30 days</p>
                        <h3>₹999</h3>
                        <button>Subscribe Now</button>
                    </div>
                    <div className="pricing-card" onClick={handleSubscriptionClick}>
                        <h2>Yearly Plan</h2>
                        <p>Access for 365 days</p>
                        <h3>₹5999</h3>
                        <button>Subscribe Now</button>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default SubscriptionPricing;
