// src/components/Layout.js
import React from 'react';
import NavBar from './NavBar'; // Import the existing NavBar component
import Footer from './Footer'; // Import the Footer component
import './Layout.css'; // Create or update this CSS file for layout styles

function Layout({ children }) {
    return (
      <div className="layout-container">
        <NavBar />
        <div className="content-container">
          {children}
        </div>
        <Footer />
      </div>
    );
  }
  
  export default Layout;
