import React from 'react';
import Layout from '../components/Layout';

const RefundPolicy = () => {
  return (
    <Layout>
      <div className="policy-page">
        <h1>Refund and Cancellation Policy</h1>
        <p>Last updated: 8th November 2024

1. General Refunds
Refunds are generally not applicable for subscriptions to our services unless data updates are not provided for more than 10 consecutive days. In such cases, users may contact us for a refund evaluation.

2. Reports
Refunds are not applicable for reports purchased through our platform.

3. Cancellation
You may cancel your subscription at any time, but refunds for the remaining term are generally not provided.

4. Exceptional Circumstances
In rare cases, exceptions may be considered on a case-by-case basis. Contact us for any queries.

5. Taxes and Charges
Applicable taxes (e.g., GST) may affect the total price. Refunds will only cover eligible amounts.

6. Processing
Refunds (if applicable) are processed through our payment gateway partner and may take several business days to reflect in your account.</p>
        {/* Add more details on refund and cancellation */}
      </div>
    </Layout>
  );
};

export default RefundPolicy;
