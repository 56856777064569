import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './NavBar.css';
import { auth } from '../firebaseConfig'; // Import Firebase auth
import { useUserContext } from '../UserContext'; // Use UserContext for global state management

function NavBar() {
    const [showResourcesDropdown, setShowResourcesDropdown] = useState(false);
    const [showUserDropdown, setShowUserDropdown] = useState(false);
    const { isLoggedIn, isSubscribed, checkSubscriptionStatus } = useUserContext(); // Assuming you have a function to refresh/check subscription

    useEffect(() => {
        // This only triggers when auth state changes, not on every render
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user) {
                // Check subscription status only when the user logs in
                await checkSubscriptionStatus();
            }
        });

        return () => unsubscribe();
    }, [checkSubscriptionStatus]);

    const handleLogout = () => {
        auth.signOut();
        // Consider resetting state via UserContext
    };

    return (
        <nav className="navbar">
            <div className="container">
            <Link to="/" className="logo-link">
    <img 
        src={`${process.env.PUBLIC_URL}/images/tm logo background 1e2a38.png`} 
        alt="Tender Managers Logo" 
        className="logo"
    />
    <div className="brand-text">
        <div className="brand-title">Tender Managers</div>
        <div className="brand-tagline">Your Partners in Growth</div>
    </div>
</Link>


                {/* Tracker Dashboard Button for logged-in and subscribed users */}
                {isLoggedIn && isSubscribed && (
                    <Link to="/tender-selection" className="tender-tracker-btn">
                        Tracker Dashboard
                    </Link>
                )}

                <ul className="nav-links">
                    <li><Link to="/about">About Us</Link></li>
                    <li><Link to="/services">Services</Link></li>
                    <li 
                        className="nav-item resources-dropdown" 
                        onMouseEnter={() => setShowResourcesDropdown(true)} 
                        onMouseLeave={() => setShowResourcesDropdown(false)}
                    >
                        <span className="nav-link">Resources</span>
                        {showResourcesDropdown && (
                            <ul className="resources-dropdown-menu">
                                <li><Link to="/resources/government-portals">Government Portals</Link></li>
                                <li><Link to="/resources/procurement-rules">Procurement Rules</Link></li>
                                <li><Link to="/resources/statistics">Statistics</Link></li>
                                <li><Link to="/resources/gem-categories">GeM Categories</Link></li>
                            </ul>
                        )}
                    </li>
                    <li><Link to="/subscription-pricing">Subscription Pricing</Link></li>
                    <li><Link to="/reports-pricing">Reports Pricing</Link></li>

                    {isLoggedIn ? (
                        <li 
                            className="nav-item user-dropdown" 
                            onMouseEnter={() => setShowUserDropdown(true)} 
                            onMouseLeave={() => setShowUserDropdown(false)}
                        >
                            <div className="user-icon-container">
                                <img 
                                    src={`${process.env.PUBLIC_URL}/images/user-icon.png`} 
                                    alt="User Icon" 
                                    className="user-icon"
                                />
                            </div>
                            {showUserDropdown && (
                                <ul className="user-dropdown-menu">
                                    <li><Link to="/my-profile">My Profile</Link></li>
                                    <li><Link to="/active-plan">Active Plan</Link></li>
                                    <li><Link to="/favorite-tenders">Favorite Tenders</Link></li>
                                    <li><Link to="/reports">Reports</Link></li>
                                    <li>
                                        <button 
                                            onClick={handleLogout}
                                            className="logout-button"
                                        >
                                            Logout
                                        </button>
                                    </li>
                                </ul>
                            )}
                        </li>
                    ) : (
                        <li><Link to="/login" className="login-btn">Login</Link></li>
                    )}
                </ul>
            </div>
        </nav>
    );
}

export default NavBar;
