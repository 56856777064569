import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css'; // Add a CSS file for subtle styling

const Footer = () => {
  return (
    <footer className="footer-container">
      <p>&copy; 2024 Tender Managers. All rights reserved.</p>
      <div className="footer-links">
        <Link to="/terms-and-conditions">Terms & Conditions</Link>
        <Link to="/privacy-policy">Privacy Policy</Link>
        <Link to="/refund-policy">Refund Policy</Link>
        <Link to="/pricing-policy">Pricing Policy</Link>
        <Link to="/contact-us">Contact Us</Link>
      </div>
    </footer>
  );
};

export default Footer;
